import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "./containers/Home";
import Login from "./containers/Login";
import Notes from "./containers/Notes";
import Signup from "./containers/Signup";
import NewLicence from "./containers/NewLicence";
import Settings from "./containers/Settings";
import Downloads from "./containers/Downloads"
import NotFound from "./containers/NotFound";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";
import ResetPassword from "./containers/ResetPassword";
import Sales from './containers/Sales';
import SalesItem from './components/SalesItem'
import Users from './containers/Users'
import BillingForm from './components/BillingForm'

export default function Routes() {
  return (
    <Switch>
      <Route exact path="/">
        <Home />
      </Route>
      <UnauthenticatedRoute exact path="/login">
        <Login />
      </UnauthenticatedRoute>
      <UnauthenticatedRoute exact path="/signup">
        <Signup />
      </UnauthenticatedRoute>
      <UnauthenticatedRoute exact path="/login/reset">
        <ResetPassword />
      </UnauthenticatedRoute>
      <AuthenticatedRoute exact path="/purchase">
        <Settings />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/purchase/:id/:mail">
        <BillingForm />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/sales/:requestId/:hasInvoice/:descriptionParam">
        <SalesItem/>
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/downloads">
        <Downloads />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/sales">
        <Sales />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/users">
        <Users />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/userlicence/:id">
        <Home/>
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/licences/new/:id/:activation/:userId">
        <NewLicence />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/licences/:id">
        <Notes />
      </AuthenticatedRoute>
      {/* Finally, catch all unmatched routes */}
      <Route>
        <NotFound />
      </Route>
    </Switch>
  );
}
