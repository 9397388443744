import React, { useState, useEffect } from "react";
import { API, Auth } from "aws-amplify";
import { Link } from "react-router-dom";
import { ListGroup, Spinner, Row, Col, FormText, Form, Button, Modal } from "react-bootstrap";
import { useAppContext } from "../libs/contextLib";
import { onError } from "../libs/errorLib";
import { useFormFields } from "../libs/hooksLib";
import "./Home.css";

export default function Users() {
    const { isAuthenticated, isUserAdmin, isUserSales } = useAppContext();
    const [userList, setUserList] = useState([])
    const [showUserModal, setShowUserModal] = useState(false)
    const [fields, handleFieldChange] = useFormFields({
        email: "",
        password: "",
        company: "",
        description: "",
        confirmPassword: "",
        trialDays: 14
    });

    function validateForm() {
        return (
            fields.email.length > 0 &&
            fields.password.length > 0 &&
            fields.password === fields.confirmPassword &&
            fields.trialDays > 0
        );
    }

    useEffect(() => {
        async function onLoad() {
            if (!isAuthenticated) {
                return;
            }
            if (!isUserAdmin && !isUserSales) {
                return;
            }

            try {
                const session = await Auth.currentSession();
                const payload = session.getIdToken().payload;
                const identityId = payload.sub;
                const jwtToken = session.getIdToken().getJwtToken();
                const uList = await loadUserList(identityId, jwtToken)
                setUserList(uList)
            } catch (e) {
                onError(e);
            }
        }

        onLoad();
    }, [isAuthenticated, isUserAdmin]);


    function loadUserList(identityId, jwtToken) {
        const config = {
            headers: {
                Authorization: `Bearer ${jwtToken}`,
            },
        };
        return API.get(
            "Dataguess",
            "/user/getuserlist/",
            config
        );
    }

    const handleClose = () => {
        setShowUserModal(false)
    }

    const handleCreateUser = async () => {
        const session = await Auth.currentSession();
        const payload = session.getIdToken().payload;
        const jwtToken = session.getIdToken().getJwtToken();
        const config = {
            headers: {
                Authorization: `Bearer ${jwtToken}`,
            },
            body: fields,
        };
        const resp = await API.post("Dataguess", "/user/createuser", config);
        console.log('resp', resp)
        let users = [...userList]
        users.push({ username: fields.email, identityId: resp, company: fields.company, description: fields.description, trialDays: fields.trialDays })
        setUserList(users)
        handleClose()
    }

    return (
        <Form>
            <ListGroup>
                <Button onClick={() => setShowUserModal(true)}>Create New User</Button>
                {userList.map((user) => (
                    <ListGroup.Item key={user.identityId}>
                        <Row>
                            <Col>
                                <FormText>{user.username}</FormText>
                            </Col>
                            <Col>
                                <FormText>{user.identityId}</FormText>
                            </Col>
                            <Col>
                                <FormText>{user.company}</FormText>
                            </Col>
                            <Col>
                                <FormText>{user.description}</FormText>
                            </Col>
                            <Col>
                                <Link
                                    to={`/userlicence/${user.identityId}`}
                                >
                                    <Button right>Licenses</Button>
                                </Link>
                            </Col>
                            <Col>
                                <Link
                                    to={`/purchase/${user.identityId}/${user.username}`}
                                >
                                    <Button right>Create Form</Button>
                                </Link>
                            </Col>
                        </Row>
                    </ListGroup.Item>
                ))}
            </ListGroup>
            <Modal show={showUserModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Create User</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group controlId="email" size="lg">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                            autoFocus
                            type="email"
                            value={fields.email}
                            onChange={handleFieldChange}
                        />
                    </Form.Group>

                    <Form.Group controlId="company" size="lg">
                        <Form.Label>Company</Form.Label>
                        <Form.Control
                            autoFocus
                            type="text"
                            value={fields.company}
                            onChange={handleFieldChange}
                        />
                    </Form.Group>
                    <Form.Group controlId="description" size="lg">
                        <Form.Label>Description</Form.Label>
                        <Form.Control
                            autoFocus
                            type="text"
                            value={fields.description}
                            onChange={handleFieldChange}
                        />
                    </Form.Group>
                    <Form.Group controlId="trialDays" size="lg">
                        <Form.Label>Free Trial Days</Form.Label>
                        <Form.Control
                            autoFocus
                            type="number"
                            value={fields.trialDays}
                            onChange={handleFieldChange}
                        />
                    </Form.Group>
                    <Form.Group controlId="password" size="lg">
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                            type="password"
                            value={fields.password}
                            onChange={handleFieldChange}
                        />
                        <FormText>
                            *Minimum password length of at least 8 characters, as well as
                            requiring uppercase[A-Z], numeric[0-9], and special
                            characters[#,&,% etc.]
                         </FormText>
                    </Form.Group>
                    <Form.Group controlId="confirmPassword" size="lg">
                        <Form.Label>Confirm Password</Form.Label>
                        <Form.Control
                            type="password"
                            onChange={handleFieldChange}
                            value={fields.confirmPassword}
                        />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button variant="primary" disabled={!validateForm()} onClick={() => handleCreateUser()}>
                        Create
                    </Button>
                </Modal.Footer>
            </Modal>
        </Form>
    );
}
