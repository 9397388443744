const dev = {
  STRIPE_KEY: "pk_test_v1amvR35uoCNduJfkqGB8RLD",
  s3: {
    REGION: "eu-central-1",
    BUCKET: "dev-notes-infra-s3-uploads4f6eb0fd-1taash9pf6q1f",
  },
  apiGateway: {
    REGION: "eu-central-1",
    URL: "https://uv0vguzz34.execute-api.eu-central-1.amazonaws.com/",
    // URL: "http://localhost:8000"
  },
  cognito: {
    REGION: "eu-central-1",
    USER_POOL_ID: "eu-central-1_Od33elWHr",
    APP_CLIENT_ID: "1c90knrs6f3c7demp3jpillott",
    IDENTITY_POOL_ID: "eu-central-1:659706f5-4a30-4eba-a7b0-f5966e4249bd",
  },
};

const prod = {
  STRIPE_KEY: "pk_test_51IMU5cKvhmbFzv6tHCJFjioppTOKcV7DePKbVq6kCSv3AHoSeaFQ9sJmTJLaTZa6GyUOYGli444kMYigRYOpq9wo006bJUkQCF",
  s3: {
    REGION: "eu-central-1",
    BUCKET: "prod-notes-infra-s3-uploads4f6eb0fd-1838t5x17uk5u",
  },
  apiGateway: {
    REGION: "eu-central-1",
    URL: "https://uv0vguzz34.execute-api.eu-central-1.amazonaws.com/"
  },
  cognito: {
    REGION: "eu-central-1",
    USER_POOL_ID: "eu-central-1_Od33elWHr",
    APP_CLIENT_ID: "1c90knrs6f3c7demp3jpillott",
    IDENTITY_POOL_ID: "eu-central-1:659706f5-4a30-4eba-a7b0-f5966e4249bd",
  },
};

const config = {
  features: [
    { name: "Modbus TCP", value: false, description: 'Modbus TCP Read/Write', componentId: 1, disabled: false , visible: true},
    { name: "Modbus TCP", value: false, description: 'Modbus TCP Read/Write', componentId: 2, disabled: false , visible: false},
    { name: "Siemens S7", value: false, description: 'S7 Protocol Read/Write', componentId: 3, disabled: false , visible: true},
    { name: "Siemens S7", value: false, description: 'S7 Protocol Read/Write', componentId: 4, disabled: false , visible: false},
    { name: "MQTT", value: false, description: 'MQTT Read/Write', componentId: 5, disabled: false , visible: true},
    { name: "MQTT", value: false, description: 'MQTT Read/Write', componentId: 6, disabled: false , visible: false},
    { name: "OPC UA", value: false, description: 'OPC UA Read/Write', componentId: 7, disabled: false , visible: true},
    { name: "OPC UA", value: false, description: 'OPC UA Read/Write', componentId: 8, disabled: false , visible: false},
    { name: "Twincat", value: false, description: 'Twincat Read/Write', componentId: 9, disabled: false , visible: true},
    { name: "Twincat", value: false, description: 'Twincat Read/Write', componentId: 10, disabled: false , visible: false},
    { name: "InfluxDB", value: false, description: 'Write data to InfluxDB', componentId: 11, disabled: false , visible: true},
    { name: "CSV File Write", value: false, description: 'Write data to CSV file', componentId: 12, disabled: false , visible: true},
    { name: "JSON File Write", value: false, description: 'Write data to JSON file', componentId: 13, disabled: false , visible: true},
    { name: "Datawatch", value: true, description: 'Watch data on', componentId: 14, disabled: true , visible: true},
    { name: "Math & Logic", value: true, description: 'Math & Logic', componentId: 15, disabled: true , visible: true},
    { name: "Array Functions", value: false, description: 'Array Functions', componentId: 16, disabled: false , visible: true},
    { name: "Type Conversion", value: true, description: 'Type Conversion', componentId: 17, disabled: true , visible: true},
    { name: "To JSON", value: true, description: 'To JSON', componentId: 18, disabled: true , visible: true},
    { name: "From JSON", value: true, description: 'From JSON', componentId: 19, disabled: true , visible: true},
    { name: "Array", value: false, description: 'Array', componentId: 20, disabled: false , visible: true},
    { name: "Python Script Editor", value: false, description: 'Write and execute python code.', componentId: 21, disabled: false , visible: true},
    { name: "HTTP", value: false, description: 'Make HTTP requests', componentId: 22, disabled: false , visible: true},
    { name: "AI - ONNX", value: false, description: 'Execute ONNX models', componentId: 23, disabled: false , visible: true},
    { name: "Scikit-learn", value: false, description: 'Scikit-learn', componentId: 24, disabled: false , visible: true},
    { name: "Variables", value: true, description: "Variables", componentId: 25, disabled: true, visible: true},
    { name: "HTTP Server", value: false, description: "HTTP Server", componentId: 26, disabled: false, visible: true},
    { name: "Reserved", value: false, description: "Reserved", componentId: 27, disabled: false, visible: true},
    { name: "Ethernet/IP", value: false, description: "Ethernet/IP Read Component", componentId: 28, disabled: false, visible: true},
    { name: "Camera", value: false, description: "Camera Component", componentId: 29, disabled: false, visible: true},
    { name: "FTP/SFTP Write", value: false, description: "FTP/SFTP Write", componentId: 30, disabled: false, visible: true},
    { name: "Image Stream", value: false, description: "Image Stream", componentId: 31, disabled: false, visible: true},
    { name: "Computer Vision Functions", value: false, description: "Computer Vision Functions", componentId: 32, disabled: false, visible: true},
    { name: "MTConnect", value: false, description: "MTConnect Read Component", componentId: 33, disabled: false, visible: true},
    { name: "OPC-XML Read", value: false, description: "OPC-XML Read Component", componentId: 34, disabled: false, visible: true}
  ],
  
  s_features: [
    { name: "Email support", value: false, description: 'Helping to solve cases you faced.' },
    { name: "Remote support (4 hours)", value: false, description: 'Helping to solve cases you faced.' },
    { name: "Remote support (6 hours)", value: false, description: 'Helping to solve cases you faced.' },
    { name: "Training (1 hours)", value: false, description: 'Helping to solve cases you faced.' },
    { name: "Training (4 hours)", value: false, description: 'Helping to solve cases you faced.' },
    { name: "Phone Support (2 hours)", value: false, description: 'Helping to solve cases you faced.' },
    { name: "Phone Support (4 hours)", value: false, description: 'Helping to solve cases you faced.' },
    { name: "Implementation support", value: false, description: 'Helping to solve cases you faced.' },
  ],
  // Add common config values here
  MAX_ATTACHMENT_SIZE: 5000000,
  // Default to dev if not set
  ...(process.env.REACT_APP_STAGE === "prod" ? prod : dev),
};

export default config;
