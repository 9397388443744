import React, { useState, useEffect } from "react";
import { API, Auth } from "aws-amplify";
import { useParams, useHistory } from "react-router-dom";
import { ListGroup, Spinner, Row, Col, FormText, Form, Button } from "react-bootstrap";
import { useAppContext } from "../libs/contextLib";
import { useFormFields } from "../libs/hooksLib";
import { onError } from "../libs/errorLib";
import config from '../config'


export default function SalesItem() {
    const { isAuthenticated, isUserAdmin } = useAppContext();
    const [sales, setSales] = useState([])
    const [selectedFeatures, setSelectedFeatures] = useState([])
    const [supportFeatures, setSupportFeatures] = useState([])
    const { requestId, hasInvoice, descriptionParam } = useParams();
    const [invoiced, setInvoiced] = useState(false)
    const [desc, setDesc] = useState("")
    const history = useHistory();

    const [fields, handleFieldChange] = useFormFields({
        dataflowCount: 3,
    });

    useEffect(() => {
        async function onLoad() {
            if (hasInvoice === "true")
                setInvoiced(hasInvoice)

            if (descriptionParam !== "undefined")
                setDesc(descriptionParam)

            if (!isAuthenticated) {
                return;
            }

            try {
                const session = await Auth.currentSession();
                const payload = session.getIdToken().payload;
                const identityId = payload.sub;
                const jwtToken = session.getIdToken().getJwtToken();
                const resp = await loadSales(identityId, jwtToken)
                setSales(resp)

                let features = resp.selectedFeatures
                for (let fIndex in config.features) {
                    const currentFeature = config.features[fIndex]
                    let found = false
                    for (let featureIndex in features) {
                        const currentSelectedFeature = features[featureIndex]
                        if (currentSelectedFeature.name === currentFeature.name) {
                            found = true
                            break
                        }
                    }
                    if (found === false) {
                        features.push(currentFeature)
                    }
                }

                setSelectedFeatures(features)
                if (resp.supportFeatures !== undefined) {
                    let sFeatures = resp.supportFeatures

                    for (let fIndex in config.s_features) {
                        const currentFeature = config.s_features[fIndex]
                        let found = false
                        for (let featureIndex in sFeatures) {
                            const currentSelectedFeature = sFeatures[featureIndex]
                            if (currentSelectedFeature.name === currentFeature.name) {
                                found = true
                                break
                            }
                        }
                        if (found === false) {
                            sFeatures.push(currentFeature)
                        }
                    }


                    setSupportFeatures(sFeatures)
                }
            } catch (e) {
                onError(e);
            }
        }

        onLoad();
    }, [isAuthenticated]);

    function addToList(name, val, id) {
        const newFeatures = [...selectedFeatures]
        for (let featureIndex in newFeatures) {
            if (newFeatures[featureIndex].name === name) {
                newFeatures[featureIndex].value = val
                newFeatures[featureIndex].componentId = id
                break
            }
        }
        setSelectedFeatures(newFeatures)
    }

    function addToSupportList(name, val) {
        const newFeatures = [...supportFeatures]
        for (let featureIndex in newFeatures) {
            if (newFeatures[featureIndex].name === name) {
                newFeatures[featureIndex].value = val
                break
            }
        }
        setSupportFeatures(newFeatures)
    }


    function updateForm(jwtToken) {
        const config = {
            headers: {
                Authorization: `Bearer ${jwtToken}`,
            },
            body: { requestId, selectedFeatures, supportFeatures, dataflowCount: fields.dataflowCount }
        };
        return API.post("Dataguess", "/user/purchase/request/update", config);
    }


    const handlePurchaseUpdate = async () => {
        const session = await Auth.currentSession()
        const jwtToken = session.getIdToken().getJwtToken();

        const resp = await updateForm(jwtToken)
        checkInvoice()
        history.goBack()
    }


    function loadSales(identityId, jwtToken) {
        const config = {
            headers: {
                Authorization: `Bearer ${jwtToken}`,
            },
        };
        return API.get(
            "Dataguess",
            "/user/purchase/request/" + requestId,
            config
        );
    }

    const checkInvoice = async () => {
        const session = await Auth.currentSession();
        const payload = session.getIdToken().payload;
        const identityId = payload.sub;
        const jwtToken = session.getIdToken().getJwtToken();
        const config = {
            headers: {
                Authorization: `Bearer ${jwtToken}`
            },
            body: { identityId: identityId, requestId: requestId, invoiced: invoiced, description: desc },
        };
        API.post(
            "Dataguess",
            "/user/purchase/invoiced",
            config
        );
    }

    return (
        <Form className="BillingForm">
            <ListGroup>
                {selectedFeatures.map((feature) => (
                    <ListGroup.Item key={feature.name}>
                        <div key={feature.name} className="mb-3">
                            <Form.Check type="checkbox" id={feature.name}>
                                <Form.Check.Input type="checkbox" isValid disabled={feature.disabled} checked={feature.value} onChange={(e) => addToList(feature.name, e.target.checked, feature.componentId)} />
                                <Form.Check.Label>{feature.name}</Form.Check.Label>
                                <Form.Control.Feedback type="valid">{feature.description}</Form.Control.Feedback>
                            </Form.Check>
                        </div>
                    </ListGroup.Item>
                ))}
                <h2>Dataflow</h2>
                <Form.Group size="lg" controlId="dataflowCount">
                    <Form.Label>Dataflow Count</Form.Label>
                    <Form.Control
                        min="3"
                        type="number"
                        value={fields.dataflowCount}
                        onChange={handleFieldChange}
                        placeholder="Number of dataflows"
                    />
                </Form.Group>
                <h2>Support</h2>
                {supportFeatures.map((feature) => (
                    <ListGroup.Item key={feature.name}>
                        <div key={feature.name} className="mb-3">
                            <Form.Check type="checkbox" id={feature.name}>
                                <Form.Check.Input type="checkbox" isValid checked={feature.value} onChange={(e) => addToSupportList(feature.name, e.target.checked)} />
                                <Form.Check.Label>{feature.name}</Form.Check.Label>
                                <Form.Control.Feedback type="valid">{feature.description}</Form.Control.Feedback>
                            </Form.Check>
                        </div>
                    </ListGroup.Item>
                ))}
                {isUserAdmin &&
                    <Col className="justify-content-md-center">
                        <Row>
                            <Form.Group controlId="formBasicCheckbox" key={requestId} >
                                <Form.Check type="checkbox" label="Invoiced" checked={typeof (invoiced) !== undefined ? invoiced : false} onChange={(e) => setInvoiced(e.target.checked)} />
                            </Form.Group>
                        </Row>
                        <Row className="justify-content-md-center">
                            <Form.Group controlId="description">
                                <Form.Control
                                    value={desc}
                                    as="textarea"
                                    placeholder="Description"
                                    onChange={(e) => setDesc(e.target.value)}
                                />

                            </Form.Group>
                        </Row>
                    </Col>
                }
                <Button onClick={() => handlePurchaseUpdate()}>
                    Update
                </Button>
            </ListGroup>
        </Form>
    );
}
