import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import { loadStripe } from "@stripe/stripe-js";
import { Elements, useStripe } from "@stripe/react-stripe-js";
import LoaderButton from "./LoaderButton";
import { useFormFields } from "../libs/hooksLib";
import { onError } from "../libs/errorLib";
import { API, Auth } from "aws-amplify";
import { useAppContext } from "../libs/contextLib";
import { useHistory, useParams } from "react-router-dom";
import "./BillingForm.css";
import { Button, ListGroup, Row, Col, Alert } from "react-bootstrap";
import config from '../config'


function BillingForm({ isLoading, onSubmit, ...props }) {
  const stripe = useStripe();

  const { isAuthenticated } = useAppContext();

  const [totalPrice, setTotalPrice] = useState(0);

  const [productPrices, setProductPrices] = useState([]);

  const [selectedFeatures, setSelectedFeatures] = useState([]);

  const [supportFeatures, setSupportFeatures] = useState([])

  const [showAlert, setShowAlert] = useState(false)

  const [fields, handleFieldChange] = useFormFields({
    name: "",
    dataflowCount: 3,
  });

  const history = useHistory();

  const { id, mail } = useParams()

  const [isProcessing, setIsProcessing] = useState(false);
  const [licences, setLicences] = useState([]);

  isLoading = isProcessing || isLoading;


  useEffect(() => {
    async function onLoad() {
      if (!isAuthenticated) {
        return;
      }

      try {
        const session = await Auth.currentSession();
        const jwtToken = session.getIdToken().getJwtToken();
        const prices = await loadProductPrices(jwtToken);
        setProductPrices(prices);
        setSelectedFeatures(config.features)
        setSupportFeatures(config.s_features)
      } catch (e) {
        onError(e);
      }
    }

    onLoad();
  }, [isAuthenticated]);

  function loadProductPrices(jwtToken) {
    const config = {
      headers: {
        Authorization: `Bearer ${jwtToken}`,
      },
    };
    return API.get("Dataguess", "/billing/getprices/", config);
  }

  function checkout(identityId, jwtToken) {
    const config = {
      headers: {
        Authorization: `Bearer ${jwtToken}`,
      },
      body: { identityId, licences },
    };
    return API.post("Dataguess", "/billing/checkout", config);
  }

  function addToList(name, val, id) {
    const newFeatures = [...selectedFeatures]
    for (let featureIndex in newFeatures) {
      if (newFeatures[featureIndex].name === name) {
        newFeatures[featureIndex].value = val
        newFeatures[featureIndex].componentId = id
        break
      }
    }
    setSelectedFeatures(newFeatures)
  }

  function addToSupportList(name, val) {
    const newFeatures = [...supportFeatures]
    for (let featureIndex in newFeatures) {
      if (newFeatures[featureIndex].name === name) {
        newFeatures[featureIndex].value = val
        break
      }
    }
    setSupportFeatures(newFeatures)
  }

  function sendForm(identityId, jwtToken, email) {
    const newFeatures = [...selectedFeatures]
    for (let featureIndex in newFeatures) {
      if (newFeatures[featureIndex].componentId === 1 || newFeatures[featureIndex].componentId === 3 || newFeatures[featureIndex].componentId === 5 || newFeatures[featureIndex].componentId === 7 || newFeatures[featureIndex].componentId === 9) {
        if (newFeatures[featureIndex].value === true) {
          newFeatures[parseInt(featureIndex) + 1].value = true
        }
      }
    }
    const config = {
      headers: {
        Authorization: `Bearer ${jwtToken}`,
      },
      body: { identityId, email, selectedFeatures: newFeatures, supportFeatures, dataflowCount: fields.dataflowCount}
    };
    return API.post("Dataguess", "/user/purchase/request", config);
  }

  const handlePurchaseRequest = async () => {
    const session = await Auth.currentSession()
    const payload = session.getIdToken().payload
    const identityId = payload.sub
    const email = payload.email
    const jwtToken = session.getIdToken().getJwtToken();

    if (id !== undefined) {
      const resp = await sendForm(id, jwtToken, mail)
    } else {
      const resp = await sendForm(identityId, jwtToken, email)
    }
    setShowAlert(true)
    // history.push('/')
  }

  return (
    <Form className="BillingForm">
      <h2>What features do you want?</h2>
      <ListGroup>
        {selectedFeatures.filter(s => s.visible === true).map((feature) => (
          <ListGroup.Item key={feature.name}>
            <div key={feature.name} className="mb-3">
              <Form.Check type="checkbox" id={feature.name}>
                <Form.Check.Input type="checkbox" isValid disabled={feature.disabled} checked={feature.value} onChange={(e) => addToList(feature.name, e.target.checked, feature.componentId)} />
                <Form.Check.Label>{feature.name}</Form.Check.Label>
                <Form.Control.Feedback type="valid">{feature.description}</Form.Control.Feedback>
              </Form.Check>
            </div>
          </ListGroup.Item>
        ))}
        <h2>Dataflow</h2>
        <Form.Group size="lg" controlId="dataflowCount">
          <Form.Label>Dataflow Count</Form.Label>
          <Form.Control
            min="3"
            type="number"
            value={fields.dataflowCount}
            onChange={handleFieldChange}
            placeholder="Number of dataflows"
          />
        </Form.Group>
        <h2>Support</h2>
        {supportFeatures.map((feature) => (
          <ListGroup.Item key={feature.name}>
            <div key={feature.name} className="mb-3">
              <Form.Check type="checkbox" id={feature.name}>
                <Form.Check.Input type="checkbox" isValid checked={feature.value} onChange={(e) => addToSupportList(feature.name, e.target.checked)} />
                <Form.Check.Label>{feature.name}</Form.Check.Label>
                <Form.Control.Feedback type="valid">{feature.description}</Form.Control.Feedback>
              </Form.Check>
            </div>
          </ListGroup.Item>
        ))}
        {showAlert && <Alert
          variant="info"
          size="md"
          onClose={() => setShowAlert(false)}
          dismissible
        >
          <Alert.Heading>Request created</Alert.Heading>
          <p>Sales personal will contact with you soon.</p>
        </Alert>}
        <Button onClick={() => handlePurchaseRequest()}>
          Send Form
        </Button>
      </ListGroup>
    </Form>
  );
}

const stripePromise = loadStripe(
  "pk_test_51IMU5cKvhmbFzv6tHCJFjioppTOKcV7DePKbVq6kCSv3AHoSeaFQ9sJmTJLaTZa6GyUOYGli444kMYigRYOpq9wo006bJUkQCF"
);

const BillingApp = () => {
  return (
    <Elements stripe={stripePromise}>
      <BillingForm />
    </Elements>
  );
};

export default BillingApp;
