import React, { useState } from "react";
import { Auth } from "aws-amplify";
import { Form, FormText } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import CountrySelector from "../containers/CountrySelector"
import LoaderButton from "../components/LoaderButton";
import { useAppContext } from "../libs/contextLib";
import { useFormFields } from "../libs/hooksLib";
import { onError } from "../libs/errorLib";
import "./Signup.css";

export default function Signup() {
  const [fields, handleFieldChange] = useFormFields({
    email: "",
    password: "",
    name: "",
    phone: "",
    confirmPassword: "",
    confirmationCode: ""
  });
  const history = useHistory();
  const [newUser, setNewUser] = useState(null);
  const { userHasAuthenticated } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);
  const [terms, setTerms] = useState(false);
  const [marketing, setMarketing] = useState(true);
  const [country, setCountry] = useState('');

  function validateForm() {
    return (
      fields.email.length > 0 &&
      fields.password.length > 0 &&
      fields.password === fields.confirmPassword &&
      terms
    );
  }

  function validateConfirmationForm() {
    return fields.confirmationCode.length > 0;
  }

  async function handleSubmit(event) {
    event.preventDefault();

    setIsLoading(true);

    try {
      const newUser = await Auth.signUp({
        username: fields.email,
        password: fields.password,
        attributes: {
          "custom:user_name": fields.name,
          "custom:user_phone": fields.phone,
          "custom:user_country": country,
          "custom:company": fields.company,
          "custom:marketing_mailing": (marketing ? "1" : "0")
        }
      });
      setIsLoading(false);
      setNewUser(newUser);
    } catch (e) {
      onError(e);
      setIsLoading(false);
    }
  }

  async function handleConfirmationSubmit(event) {
    event.preventDefault();

    setIsLoading(true);

    try {
      await Auth.confirmSignUp(fields.email, fields.confirmationCode);
      await Auth.signIn(fields.email, fields.password);

      userHasAuthenticated(true);
      history.push("/");
    } catch (e) {
      onError(e);
      setIsLoading(false);
    }
  }

  function renderConfirmationForm() {
    return (
      <div className="Login">
        <Form onSubmit={handleConfirmationSubmit} className="login-form">
          <Form.Group controlId="confirmationCode" size="lg">
            <Form.Label>Confirmation Code</Form.Label>
            <Form.Control
              autoFocus
              type="tel"
              onChange={handleFieldChange}
              value={fields.confirmationCode}
            />
            <Form.Text muted>Please check your email for the code.</Form.Text>
          </Form.Group>
          <LoaderButton
            block
            size="lg"
            type="submit"
            variant="success"
            isLoading={isLoading}
            disabled={!validateConfirmationForm()}
          >
            Verify
          </LoaderButton>
        </Form>
      </div>
    );
  }

  function renderForm() {
    return (
      <>
        <h2 className="pb-3 mt-0 mb-3 border-bottom">Signup</h2>
        <div className="Login">
          <Form onSubmit={handleSubmit} className="login-form">
            <Form.Group controlId="name" size="lg">
              <Form.Label>Name</Form.Label>
              <Form.Control
                autoFocus
                type="text"
                value={fields.name}
                onChange={handleFieldChange}
              />
            </Form.Group>
            <Form.Group controlId="email" size="lg">
              <Form.Label>Email</Form.Label>
              <Form.Control
                autoFocus
                type="email"
                value={fields.email}
                onChange={handleFieldChange}
              />
            </Form.Group>
            <Form.Group controlId="phone" size="lg">
              <Form.Label>Phone</Form.Label>
              <Form.Control
                autoFocus
                type="number"
                value={fields.phone}
                onChange={handleFieldChange}
              />
            </Form.Group>
            <Form.Group controlId="company" size="lg">
              <Form.Label>Company</Form.Label>
              <Form.Control
                autoFocus
                type="text"
                value={fields.company}
                onChange={handleFieldChange}
              />
            </Form.Group>
            <Form.Group controlId="password" size="lg">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                value={fields.password}
                onChange={handleFieldChange}
              />
              <FormText>
                *Minimum password length of at least 8 characters, as well as
                requiring uppercase[A-Z], numeric[0-9], and special
                characters[#,&,% etc.]
              </FormText>
            </Form.Group>
            <Form.Group controlId="confirmPassword" size="lg">
              <Form.Label>Confirm Password</Form.Label>
              <Form.Control
                type="password"
                onChange={handleFieldChange}
                value={fields.confirmPassword}
              />
            </Form.Group>

            <Form.Group controlId="country" size="lg">
              <Form.Label>Country</Form.Label>
              <CountrySelector setCountry={setCountry} />
            </Form.Group>

            <Form.Group controlId="terms" size="lg">
              <input
                required
                type="checkbox"
                onChange={(e) => setTerms(e.target.checked)}
                checked={terms}
              />
              <Form.Label required><span style={{paddingLeft: 5}}>I agree to the <a style={{ display: "contents" }} href="https://dataguessdata.s3.eu-central-1.amazonaws.com/DATAGUESS+EULA.pdf">End User License Agreement (EULA)</a></span></Form.Label>
            </Form.Group>
            <Form.Group controlId="marketing" size="lg">
              <input
                type="checkbox"
                onChange={(e) => setMarketing(e.target.checked)}
                checked={marketing}
              />
              <Form.Label><span style={{paddingLeft: 5}}>I agree to receive marketing email communications from Dataguess</span></Form.Label>
            </Form.Group>
            <LoaderButton
              block
              size="lg"
              type="submit"
              isLoading={isLoading}
              disabled={!validateForm()}
            >
              Signup
            </LoaderButton>
          </Form>
        </div>
      </>
    );
  }

  return (
    <div className="">
      {newUser === null ? renderForm() : renderConfirmationForm()}
    </div>
  );
}
