import React, { useState, useEffect } from "react";
import { API, Auth } from "aws-amplify";
import { Link, useHistory } from "react-router-dom";
import { BsArrowUpRight } from "react-icons/bs";
import { LinkContainer } from "react-router-bootstrap";
import { useParams } from "react-router-dom";
import { useAppContext } from "../libs/contextLib";
import { onError } from "../libs/errorLib";
import { ListGroup, Badge, Col, Row, Container, Button, Modal } from "react-bootstrap";
import { CopyToClipboard } from "react-copy-to-clipboard";
import "./Home.css";

export default function Home() {
  const [licences, setLicences] = useState([]);
  const { isAuthenticated } = useAppContext();
  const [isLoading, setIsLoading] = useState(true);
  const { id } = useParams();
  const [isUserAdmin, setUserAdmin] = useState(false)
  const [show, setShow] = useState(false)
  const [removedLicenceId, setRemovedLicenceId] = useState("")
  const [removedLicenceIndex, setRemovedLicenceIndex] = useState("")
  const [isStudent, setIsStudent] = useState(false)
  const history = useHistory()

  useEffect(() => {
    async function onLoad() {
      if (!isAuthenticated) {
        return;
      }

      try {
        const session = await Auth.currentSession();
        const payload = session.getIdToken().payload;

        const sToken = payload["email"].split("@")[1]
        if (sToken.includes("edu")) {
          setIsStudent(true)
        }

        if (payload["cognito:groups"] !== undefined) {
          if (payload["cognito:groups"][0] === "admin") {
            setUserAdmin(true)
          }
        }

        const jwtToken = session.getIdToken().getJwtToken();
        const identityId = payload.sub;
        let licences = await loadLicences(identityId, jwtToken);
        // console.log('licences', licences)
        for (let licenceIndex in licences) {
          let currentLicence = licences[licenceIndex];
          if (currentLicence.activationCode.startsWith("null")) {
            currentLicence.activationCode = "";
          }
        }
        setLicences(licences);
      } catch (e) {
        onError(e);
      }
      setIsLoading(false);
    }

    onLoad();
  }, [isAuthenticated]);

  function loadLicences(identityId, jwtToken) {
    if (id !== undefined) {
      identityId = id
    }
    const config = {
      headers: {
        Authorization: `Bearer ${jwtToken}`,
      },
    };
    return API.get(
      "Dataguess",
      "/activation/getlicences/" + identityId,
      config
    );
  }

  const handleRemoveLicence = async () => {
    const session = await Auth.currentSession();
    const jwtToken = session.getIdToken().getJwtToken();
    const config = {
      headers: {
        Authorization: `Bearer ${jwtToken}`
      }
    };
    const resp = await API.get(
      "Dataguess",
      "/user/removelicence/" + id + "/" + removedLicenceId + '/' + removedLicenceIndex,
      config
    );

    if (resp.err === false) {
      const tempLicences = [...licences]
      tempLicences.splice(removedLicenceIndex, 1)
      console.log('tempLicences', tempLicences)
      setLicences(tempLicences)
    }

    handleClose()
  }

  const handleClose = () => setShow(false);

  const handleShow = (licenceId, licenceIndex) => {
    // console.log(licenceId, licenceIndex)
    setRemovedLicenceId(licenceId)
    setRemovedLicenceIndex(licenceIndex)
    setShow(true)
  }

  function renderLicenceList(licences) {
    return (
      <>
        {licences.length > 0 &&
          licences.map(
            ({
              licenceKey,
              activationCode,
              createdAt,
              workflowCount,
              licenceId,
              licences,
              paid,
            }, licenceIndex) => (
              // <LinkContainer key={licenceKey} to={`/licences/${licenceKey}`}>
              <>
                <ListGroup.Item
                  className="mt-4"
                  key={licenceId}
                  variant={licenceKey === "" ? "light" : ""}
                  style={{ "border-top-width": 1 }}
                >
                  <Container>
                    <br />
                    {/* <Row>
                <Col md={2}>
                  <span className="font-weight-bold">
                    Licence:
                  </span>
                </Col>
                <Col md={8}>
                  <span className="font-weight-bold">
                    {licenceKey}
                  </span>
                </Col>
                {licenceKey !== "" &&
                  <CopyToClipboard text={licenceKey}>
                    <Button>copy</Button>
                  </CopyToClipboard>
                }
              </Row> */}
                    <Row>
                      <Col className="dashboard-main-col">
                        <Link
                          to={`/licences/new/${licenceId}/${activationCode === "" ? "null" : activationCode
                            }/${id !== undefined ? id : "null"}`}
                        >
                          <Button right>Generate License</Button>
                        </Link>
                      </Col>
                    </Row>
                    {isUserAdmin &&
                      <Row>
                        <Col className="dashboard-main-col">
                          <Button right onClick={() => handleShow(licenceId, licenceIndex)}>Remove Licence</Button>
                        </Col>
                      </Row>
                    }
                    {licenceKey === "" && <br />}
                    <Row>
                      <Col md={2}>
                        <span className="text-muted">Activation Code:</span>
                      </Col>
                      <Col md={8}>
                        <span className="text-muted">{activationCode}</span>
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      <Col md={2}>
                        <span className="text-muted">Created At:</span>
                      </Col>
                      <Col md={8}>
                        <span className="font-weight-bold">
                          {new Date(createdAt).toLocaleString()}
                        </span>
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      <Container>
                        <Row>
                          <Col md={2}>
                            <span className="text-muted"></span>
                          </Col>
                          <Col md={8} className="font-weight-bold">
                          </Col>
                          {!paid && (
                            <Badge style={{ "font-size": "12px" }} variant="info">{!isStudent ? "Demo licence for 14 days" : "Student licence for 30 days"}</Badge>
                          )}
                        </Row>
                      </Container>
                    </Row>
                    <br />
                    <text className="text-muted">Generated Licenses:</text>
                    <ListGroup>
                      {!isLoading && renderLicencesInGroup(licences)}
                    </ListGroup>
                  </Container>
                </ListGroup.Item>
              </>
              // </LinkContainer>
            )
          )}
        {licences.length == 0 && (
          <LinkContainer to="/purchase">
            <ListGroup.Item action className="py-3 text-nowrap text-truncate">
              <BsArrowUpRight size={20} />
              <span className="ml-2 font-weight-bold">Buy licence</span>
            </ListGroup.Item>
          </LinkContainer>
        )}
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Remove Licence</Modal.Title>
          </Modal.Header>
          <Modal.Body>You are about to remove user licence!</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={() => handleRemoveLicence()}>
              OK
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }

  function renderLicencesInGroup(licences) {
    return (
      <>
        {licences.length > 0 &&
          licences.map(({ licenceKey, versionNumber }) => (
            // <LinkContainer key={licenceKey} to={`/licences/${licenceKey}`}>
            <ListGroup.Item
              key={licenceKey}
              variant={licenceKey === "" ? "light" : ""}
            >
              <Container>
                <Row>
                  <Col md={8}>
                    <span className="font-weight-bold" style={{ "overflow-wrap": "anywhere" }}>{licenceKey}</span>
                  </Col>
                  <Col md={2}>
                    <span className="text-muted">{versionNumber}</span>
                  </Col>
                  <Col md={2}>
                    {licenceKey !== "" && (
                      <CopyToClipboard text={licenceKey}>
                        <Button>copy</Button>
                      </CopyToClipboard>
                    )}
                  </Col>
                </Row>
                <br />
              </Container>
            </ListGroup.Item>
          ))}
      </>
    );
  }

  function renderLander() {
    return (
      history.push('/login')
    );
  }

  function renderLicences() {
    return (
      <div className="notes">
        <h2 className="pb-3 mt-4 mb-3 border-bottom">Licenses</h2>
        <ListGroup>{!isLoading && renderLicenceList(licences)}</ListGroup>
      </div>
    );
  }

  return (
    <div className="Home">
      {isAuthenticated ? renderLicences() : renderLander()}
    </div>
  );
}