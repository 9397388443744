import React, { useState, useEffect } from "react";
import { API, Auth } from "aws-amplify";
import { Form, Alert, Container, Col } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import LoaderButton from "../components/LoaderButton";
import { onError } from "../libs/errorLib";
import "./NewNote.css";

export default function NewLicence() {
  const history = useHistory();
  const [activationCode, setActivationCode] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [versionNumber, setVersionNumber] = useState("v0.3.3");
  const [errorMessage, setErrorMessage] = useState("Your activation code is not valid.")
  const { id, activation, userId } = useParams();

  function validateForm() {
    return activationCode.length > 0;
  }

  useEffect(() => {
    if (activation === "null") setActivationCode("");
    else setActivationCode(activation);
  }, [activation]);

  async function handleSubmit(event) {
    event.preventDefault();

    setIsLoading(true);

    try {
      // const attachment = file.current ? await s3Upload(file.current) : null;

      const session = await Auth.currentSession();
      const payload = session.getIdToken().payload;
      const jwtToken = session.getIdToken().getJwtToken();
      let identityId = payload.sub;
      console.log('userId', userId)
      if (userId !== "null") {
        identityId = userId
      }
      const resp = await createLicence(activationCode, identityId, jwtToken);
      if (resp.err === true) {
        setShowAlert(true);
        setIsLoading(false);
        setErrorMessage(resp.message)
      } else {
        history.push("/");
      }
    } catch (e) {
      onError(e);
      setIsLoading(false);
    }
  }

  function createLicence(activationCode, identityId, jwtToken) {
    const config = {
      headers: {
        Authorization: `Bearer ${jwtToken}`,
      },
    };
    console.log("versionNumber", versionNumber);
    return API.get(
      "Dataguess",
      "/activation/generatelicence/" +
        id +
        "/" +
        activationCode +
        "/" +
        identityId +
        "/" +
        versionNumber,
      config
    );
  }

  return (
    <div className="NewNote">
      <h2 className="pb-3 mt-4 mb-3 border-bottom">Generate License</h2>
      {showAlert ? (
        <Container>
          <Alert
            variant="danger"
            size="md"
            onClose={() => setShowAlert(false)}
            dismissible
          >
            <Alert.Heading>Activation Error</Alert.Heading>
            <p>{errorMessage}</p>
          </Alert>
        </Container>
      ) : (
        <span></span>
      )}
      <Container>
        <Form onSubmit={handleSubmit} className="licence-code-area">
          <Form.Group controlId="content">
            <Form.Control
              value={activationCode}
              as="textarea"
              disabled={activationCode !== "" ? true : false}
              placeholder="Paste your activation code here."
              onChange={(e) => setActivationCode(e.target.value)}
            />
            <Form>
              <Form.Group controlId="form.SelectCustom">
                <Form.Label>Select Version</Form.Label>
                <Form.Control
                  defaultValue="v0.3.3"
                  as="select"
                  custom
                  onChange={(e) => setVersionNumber(e.target.value)}
                >
                  <option value="v0.1.0">v0.1.0</option>
                  <option value="v0.2.0">v0.2.0</option>
                  <option value="v0.2.1">v0.2.1</option>
                  <option value="v0.2.2">v0.2.2</option>,
                  <option value="v0.2.3">v0.2.3</option>
                  <option value="v0.3.0">v0.3.0</option>
                  <option value="v0.3.1">v0.3.1</option>
                  <option value="v0.3.2">v0.3.2</option>
                  <option value="v0.3.3">v0.3.3</option>
                  <option value="v0.4.0">v0.4.0</option>
                </Form.Control>
              </Form.Group>
            </Form>
          </Form.Group>
          {/* <Form.Group controlId="file">
          <Form.Label>Attachment</Form.Label>
          <Form.Control onChange={handleFileChange} type="file" />
        </Form.Group> */}
          <Col className="dashboard-main-col">
            <LoaderButton
              block
              type="submit"
              variant="primary"
              isLoading={isLoading}
              disabled={!validateForm()}
            >
              Generate
            </LoaderButton>
          </Col>
        </Form>
      </Container>
    </div>
  );
}
