import React, { useState, useEffect } from "react";
import { API, Auth } from "aws-amplify";
import { Link } from "react-router-dom";
import { ListGroup, Row, Col, FormText, Form, Button, Modal, ButtonGroup } from "react-bootstrap";
import { useAppContext } from "../libs/contextLib";
import { onError } from "../libs/errorLib";
import "./Home.css";

export default function Sales() {
    const { isAuthenticated, isUserAdmin, isUserSales } = useAppContext();
    const [salesList, setSalesList] = useState([])
    const [show, setShow] = useState(false)
    const [currentItem, setCurrentItem] = useState("")
    const [filterInvoices, setFilterInvoices] = useState("all")

    useEffect(() => {
        async function onLoad() {
            if (!isAuthenticated) {
                return;
            }
            if (!isUserAdmin && !isUserSales) {
                return;
            }

            try {
                const session = await Auth.currentSession();
                const payload = session.getIdToken().payload;
                const identityId = payload.sub;
                const jwtToken = session.getIdToken().getJwtToken();
                const sales = await loadSalesForms(identityId, jwtToken)
                setSalesList(sales)
            } catch (e) {
                onError(e);
            }
        }

        onLoad();
    }, [isAuthenticated, isUserAdmin]);


    function loadSalesForms(identityId, jwtToken) {
        const config = {
            headers: {
                Authorization: `Bearer ${jwtToken}`,
            },
        };
        return API.get(
            "Dataguess",
            "/user/purchase/requests",
            config
        );
    }

    const approveRequest = async () => {
        const session = await Auth.currentSession();
        const jwtToken = session.getIdToken().getJwtToken();
        const config = {
            headers: {
                Authorization: `Bearer ${jwtToken}`,
            },
            body: { identityId: currentItem.identityId, selectedFeatures: currentItem.selectedFeatures, licenceId: currentItem.requestId, dataflowCount: currentItem.dataflowCount },
        };
        API.post(
            "Dataguess",
            "/user/createlicence",
            config
        );

        const tempSalesList = [...salesList]
        tempSalesList.forEach((sale) => {
            if (sale.requestId === currentItem.requestId) {
                sale.approved = true
            }
        })

        setSalesList(tempSalesList)

        handleClose()
    }

    const handleClose = () => setShow(false);
    const handleShow = (sales) => {
        setCurrentItem(sales)
        setShow(true)
    }

    const getFilteredRequests = () => {
        if (filterInvoices === "invoiced")
            return salesList.filter(s => s.invoiced === true)
        else if (filterInvoices === "notinvoiced")
            return salesList.filter(s => !s.invoiced)
        else
            return salesList
    }

    return (

        <Form>
            <ButtonGroup aria-label="Basic example">
                <Button variant="secondary" onClick={() => setFilterInvoices("invoiced")}>Invoiced Requests</Button>
                <Button variant="secondary" onClick={() => setFilterInvoices("notinvoiced")}>Not Invoiced Requests</Button>
                <Button variant="secondary" onClick={() => setFilterInvoices("all")}>All Requests</Button>
            </ButtonGroup>
            <ListGroup>
                {getFilteredRequests().map((sales) => (
                    <ListGroup.Item key={sales.requestId}>
                        <Row>
                            <Col>
                                <FormText>{sales.email}</FormText>
                            </Col>
                            <Col>
                                <FormText>{sales.requestId}</FormText>
                            </Col>
                            <Col>
                                <FormText>{sales.createdAt}</FormText>
                            </Col>
                            <Col>
                                <FormText>{sales.ip}</FormText>
                            </Col>
                            <Col>
                                <Link
                                    to={`/sales/${sales.requestId}/${sales.invoiced}/${sales.description}`}
                                >
                                    <Button>Show</Button>
                                </Link>
                            </Col>
                            <Col>
                                <Button onClick={() => handleShow(sales)}>Approve</Button>
                            </Col>
                            {isUserAdmin &&
                                <Row>
                                    <Col>
                                        <Form.Group className="mb-3" controlId="formBasicCheckbox" key={sales.requestId} >
                                            <Form.Check type="checkbox" disabled label="Invoiced" checked={sales.invoiced} />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                       <Form.Label>{sales.description}</Form.Label>
                                    </Col>
                                </Row>
                            }
                            {/* <Col>
                                {isUserAdmin &&
                                    <Link
                                        to={`/licences/new/${sales.requestId}/${sales.activationCode ? "null" : "null"
                                            }`}
                                    >
                                        <Button disabled={sales.approved === undefined || !sales.approved} right>Generate Licence</Button>
                                    </Link>
                                }
                            </Col> */}
                        </Row>
                    </ListGroup.Item>
                ))}
            </ListGroup>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Approve Request</Modal.Title>
                </Modal.Header>
                <Modal.Body>You are about to give the user license access!</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={() => approveRequest()}>
                        OK
                    </Button>
                </Modal.Footer>
            </Modal>
        </Form>
    );
}
