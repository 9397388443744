import React, { useState, useEffect } from "react";
import { Auth } from "aws-amplify";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import ErrorBoundary from "./components/ErrorBoundary";
import { AppContext } from "./libs/contextLib";
import { Link } from 'react-router-dom'
import { onError } from "./libs/errorLib";
import Routes from "./Routes";
import "./App.css";

function App() {
  const history = useHistory();
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [isAuthenticated, userHasAuthenticated] = useState(false);
  const [isUserAdmin, userHasAdminAuth] = useState(false);
  const [isUserSales, userHasSalesAuth] = useState(false);

  useEffect(() => {
    onLoad();
  }, []);

  async function onLoad() {
    try {
      const session = await Auth.currentSession();
      const payload = session.getAccessToken().payload
      if (payload["cognito:groups"] !== undefined) {
        if (payload["cognito:groups"][0] === "admin") {
          userHasAdminAuth(true)
        }
        if (payload["cognito:groups"][0] === "sales") {
          userHasSalesAuth(true)
        }
      }
      userHasAuthenticated(true);
    } catch (e) {
      if (e !== "No current user") {
        onError(e);
      }
    }

    setIsAuthenticating(false);
  }

  async function handleLogout() {
    await Auth.signOut();

    userHasAuthenticated(false);

    history.push("/login");
  }
  const Logo =
    "https://res.cloudinary.com/dataguess/image/upload/v1631795804/logo/dataguess_new_tmtkdq.jpg";
  return (
    !isAuthenticating && (
      <div className="App container py-3">
        <Navbar collapseOnSelect bg="none" expand="md" className="mb-3">
          <LinkContainer to="/">
            <Navbar.Brand className="font-weight-bold text-muted">
              <img src={Logo} alt="Dataguess" />
            </Navbar.Brand>
          </LinkContainer>
          <Navbar.Toggle />
          <Navbar.Collapse className="justify-content-end">
            <Nav activeKey={window.location.pathname}>
              {isAuthenticated ? (
                <>
                  {(!isUserAdmin && !isUserSales) &&
                    <LinkContainer to="/">
                      <Nav.Link>Licenses</Nav.Link>
                    </LinkContainer>
                  }
                  {(isUserAdmin || isUserSales) && <LinkContainer to="/sales">
                    <Nav.Link>Sales</Nav.Link>
                  </LinkContainer>}
                  {(isUserAdmin || isUserSales) && <LinkContainer to="/users">
                    <Nav.Link>Users</Nav.Link>
                  </LinkContainer>}
                  <LinkContainer to="/downloads">
                    <Nav.Link>Downloads</Nav.Link>
                  </LinkContainer>
                  {(!isUserAdmin && !isUserSales) &&
                    <LinkContainer to="/purchase">
                      <Nav.Link>Purchase</Nav.Link>
                    </LinkContainer>
                  }
                  <Nav.Link onClick={handleLogout}>Logout</Nav.Link>
                </>
              ) : (
                <>
                  <LinkContainer to="/signup">
                    <Nav.Link>Signup</Nav.Link>
                  </LinkContainer>
                  <LinkContainer to="/login">
                    <Nav.Link>Login</Nav.Link>
                  </LinkContainer>
                </>
              )}
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        <ErrorBoundary>
          <AppContext.Provider
            value={{ isAuthenticated, userHasAuthenticated, isUserAdmin, userHasAdminAuth, isUserSales, userHasSalesAuth }}
          >
            <Routes />
          </AppContext.Provider>
        </ErrorBoundary>
      </div>
    )
  );
}

export default App;
